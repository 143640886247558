import * as t from 'typegate';
import { Language } from '../localization';

export enum ComprehensivePricingSupportedPaymentModes {
  annual = 'annual',
  quarterly = 'quarterly',
  monthly = 'monthly',
  semiAnnual = 'semiAnnual',
  singlePremium = 'singlePremium',
}

enum ComprehensivePricingCurrency {
  USD = 'usd',
  CAD = 'cad',
}

const comprehensivePricingCostSchema = t.object(
  t.property('value', t.number),
  t.property('currency', t.tsEnum(ComprehensivePricingCurrency)),
);

const comprehensivePricingCostsSchema = t.object(
  t.optionalProperty('monthlyCost', comprehensivePricingCostSchema),
  t.optionalProperty('quarterlyCost', comprehensivePricingCostSchema),
  t.optionalProperty('annualCost', comprehensivePricingCostSchema),
  t.optionalProperty('semiAnnualCost', comprehensivePricingCostSchema),
  t.optionalProperty('singlePremiumCost', comprehensivePricingCostSchema),
);
export type ComprehensivePricingCost = t.Infer<typeof comprehensivePricingCostSchema>;

export const comprehensivePricingRiderSchema = t.intersection(
  comprehensivePricingCostsSchema,
  t.object(
    t.optionalProperty('coverageAmount', t.number),
    t.property(
      'displayName',
      t.object(t.optionalProperty(Language.fr, t.string), t.optionalProperty(Language.en, t.string)),
    ),
    t.property('productCode', t.string),
  ),
);
export type ComprehensivePricingRider = t.Infer<typeof comprehensivePricingRiderSchema>;

const comprehensivePricingDisplayNameSchema = t.object(
  t.optionalProperty(Language.fr, t.string),
  t.optionalProperty(Language.en, t.string),
);

export type ComprehensivePricingDisplayName = t.Infer<typeof comprehensivePricingDisplayNameSchema>;

export const comprehensivePricingProductSchema = t.intersection(
  comprehensivePricingCostsSchema,
  t.object(
    t.optionalProperty('coverageAmount', t.number),
    t.property('displayName', comprehensivePricingDisplayNameSchema),
    t.property('productCode', t.string),
    t.property('riders', t.array(comprehensivePricingRiderSchema)),
    t.property('isJointProduct', t.boolean),
    t.property('isDuplicateCost', t.boolean),
  ),
);

export type ComprehensivePricingProduct = t.Infer<typeof comprehensivePricingProductSchema>;

enum ComprehensivePricingPartyRoleCode {
  insured = 'insured',
  owner = 'owner',
}

export const comprehensivePricingPartyRoleSchema = t.object(
  t.property(
    'displayName',
    t.object(t.optionalProperty(Language.fr, t.string), t.optionalProperty(Language.en, t.string)),
  ),
  t.property('roleCode', t.tsEnum(ComprehensivePricingPartyRoleCode)),
);

export type ComprehensivePricingPartyRole = t.Infer<typeof comprehensivePricingPartyRoleSchema>;

export const comprehensivePricingPartySchema = t.object(
  t.property('firstName', t.string),
  t.property('lastName', t.string),
  t.property('roles', t.array(comprehensivePricingPartyRoleSchema)),
  t.property('riders', t.array(comprehensivePricingRiderSchema)),
  t.property('products', t.array(comprehensivePricingProductSchema)),
);

export type ComprehensivePricingParty = t.Infer<typeof comprehensivePricingPartySchema>;

export const comprehensivePricingContractSchema = t.intersection(
  comprehensivePricingCostsSchema,
  t.object(
    t.optionalProperty('equivalentSingleAge', t.number),
    t.property('riders', t.array(comprehensivePricingRiderSchema)),
    t.property('parties', t.array(comprehensivePricingPartySchema)),
    t.optionalProperty('fees', comprehensivePricingCostsSchema),
  ),
);

export type ComprehensivePricingContract = t.Infer<typeof comprehensivePricingContractSchema>;

export const comprehensivePricingSchema = t.intersection(
  comprehensivePricingCostsSchema,
  t.object(
    t.property('supportedPaymentModes', t.array(t.tsEnum(ComprehensivePricingSupportedPaymentModes))),
    t.property('contracts', t.array(comprehensivePricingContractSchema)),
  ),
);

export type ComprehensivePricing = t.Infer<typeof comprehensivePricingSchema>;
